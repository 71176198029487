$(window).on('load', function() {
    $('body').css({
        'overflow': 'visible'
    });
    $('#preloader').fadeOut(60);
});


$(window).on('load', function() {
    // initialization of HSMegaMenu component
    $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 1025,
        hideTimeOut: 0
    });

});

$(document).on('ready', function() {
    // initialization of header
    $.HSCore.components.HSHeader.init($('#header'));

    //Testimonial Slider
    var deviceUserAgent = (/Android|webOS|iPhone|iPad|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    var sliderSettings2 = {
        rtl: false,
        loop: true,
        margin: 0,
        nav: true,
        thumbs: false,
        dots: false,
        autoplay: true,
        navSpeed: 1000,
        dragSpeed: 1000,
        autoplaySpeed: 2000,
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        responsive: {
            0: {
                items: 1
            },
            1600: {
                items: 1
            }
        }
    };
    var testomonialsCarousel = $('#testimonials-carousel');
    testomonialsCarousel.owlCarousel(sliderSettings2);
    $('.inner-tab-content').niceScroll({
        cursorcolor: "#383838"
    });
    $('#testimonials-carousel .testimonial-text').mCustomScrollbar({
        theme: "minimal-dark"
    });

    // Portfolio Slider
    $('.portfolio-slider').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        thumbs: false,
        thumbsPrerendered: false,
        autoplay: true,
        navSpeed: 1000,
        dragSpeed: 1000,
        autoplaySpeed: 2000,
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
    });

    // Footer particless Animation
    particlesJS("particles-js", {
        "particles": {
            "number": {
                "value": 35,
                "density": {
                    "enable": true,
                    "value_area": 800
                }
            },
            "color": {
                "value": "#ffffff"
            },
            "shape": {
                "type": "circle",
                "stroke": {
                    "width": 0,
                    "color": "#000000"
                },
                "polygon": {
                    "nb_sides": 6
                },
                "image": {
                    "src": "img/github.svg",
                    "width": 100,
                    "height": 100
                }
            },
            "opacity": {
                "value": 0.5,
                "random": false,
                "anim": {
                    "enable": false,
                    "speed": 1,
                    "opacity_min": 0.2,
                    "sync": false
                }
            },
            "size": {
                "value": 1,
                "random": true,
                "anim": {
                    "enable": false,
                    "speed": 40,
                    "size_min": 0.1,
                    "sync": false
                }
            },
            "line_linked": {
                "enable": true,
                "distance": 200,
                "color": "#ffffff",
                "opacity": 0.2,
                "width": 1
            },
            "move": {
                "enable": true,
                "speed": 6,
                "direction": "none",
                "random": false,
                "straight": false,
                "out_mode": "out",
                "bounce": false,
                "attract": {
                    "enable": false,
                    "rotateX": 600,
                    "rotateY": 1200
                }
            }
        },
        "interactivity": {
            "detect_on": "canvas",
            "events": {
                "onhover": {
                    "enable": false,
                    "mode": "grab"
                },
                "onclick": {
                    "enable": false,
                    "mode": "push"
                },
                "resize": true
            },
            "modes": {
                "grab": {
                    "distance": 140,
                    "line_linked": {
                        "opacity": 1
                    }
                },
                "bubble": {
                    "distance": 400,
                    "size": 40,
                    "duration": 2,
                    "opacity": 8,
                    "speed": 1
                },
                "repulse": {
                    "distance": 200,
                    "duration": 0.4
                },
                "push": {
                    "particles_nb": 4
                },
                "remove": {
                    "particles_nb": 2
                }
            }
        },
        "retina_detect": true
    });

    wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true
    })
    wow.init();

    // Select 2
    $('.select-control').select2({
        minimumResultsForSearch: -1
    });

    if ($('#bannerParallaxEffect').length >= 1) {
        var scene = document.getElementById('bannerParallaxEffect');
        var parallaxInstance = new Parallax(scene, {
            relativeInput: true
        });
        parallaxInstance.friction(0.2, 0.2);
    }

    // Home page Carosel
    var workCarousel = $('#work-carousel');
    workCarousel.owlCarousel({
        rtl: false,
        loop: true,
        margin: 0,
        nav: false,
        thumbs: false,
        autoplay: false,
        navSpeed: 1000,
        dots: false,
        dragSpeed: 1000,
        mouseDrag: false,
        autoplaySpeed: 2000,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            480: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            660: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            730: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            768: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            960: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            1000: {
                items: 1,
                mouseDrag: true,
                touchDrag: true,
                autoplay: true
            },
            1200: {
                items: 1
            },
            1600: {
                items: 1
            }
        }
    });
    $('#Arrow-Next').click(function() {
        workCarousel.trigger('next.owl.carousel', [300]);
    })
    $('#Arrow-Previous').click(function() {
        workCarousel.trigger('prev.owl.carousel', [300]);
    })
    $(workCarousel).on('mousemove', function(e) {
        if (((e.pageX - this.offsetLeft) < $(this).width() / 2)) {
            $('#Arrow-Next').fadeOut('fast');
            $('#Arrow-Previous').fadeIn('200');
        } else {
            $('#Arrow-Previous').fadeOut('fast');
            $('#Arrow-Next').fadeIn('200');
        }
    });
    $.fn.preload = function() {
        this.each(function() {
            $('<img/>')[0].src = this;
        });
    }

    // Home page Engagemant Models
    $(document).on('click', '.modelBoxAction a', function(e) {
        e.preventDefault();
        e.stopPropagation();
        var targetSec = $('.contactus-form-section');
        $('html,body').animate({
            scrollTop: targetSec.offset().top - $('header').height()
        }, 1200)
    });

    $('.why-choose-us').owlCarousel({
        thumbs: true,
        loop: true,
        items: 1,
        thumbsPrerendered: true,
         responsive: {
            0: {
                items: 1
            },
            1600: {
                items: 1
            }
        }
    });

    // Services Content Carousel
    $('.contentCarousel').owlCarousel({
        items: 1,
        loop: true,
        nav: true,
        thumbs: false,
        thumbsPrerendered: false,
        autoplay: true,
        navSpeed: 1000,
        dragSpeed: 1000,
        autoplaySpeed: 2000,
        autoplayHoverPause: true,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
    });

    // Home page hat we do
    $('.showSingle').click(function(){
          $('.jscontentWrap').removeClass("openPopup");
          $('#contentPopup-'+$(this).attr('target')).addClass("openPopup");
    });
    $('.closeBtn').click(function(){
          $('.jscontentWrap').removeClass("openPopup");
    });
    $(document).mouseup(function (e) { 
        if ($(e.target).closest(".jscontentWrap").length 
                    === 0) { 
            $(".jscontentWrap").removeClass("openPopup"); 
        } 
    }); 

});


$(window).on('load', function() {
    $('.slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
        nextArrow: false,
        prevArrow: false,
        arrows: false,
        asNavFor: '.slider-nav',
    });
    $('.slider-nav').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: '54px',
        asNavFor: '.slider-for',
        dots: false,
        prevArrow: false,
        infinite: true,
        nextArrow: false,
        centerMode: true,
        speed: 1000,
        focusOnSelect: true,
        vertical: true,
        responsive: [{
            breakpoint: 1025,
            settings: {
                slidesToShow: 1,
                centerPadding: '20px',
                vertical: false,
                swipeToSlide:false,
                draggable: false,
                centerMode: false,
                variableWidth: true
            }
        }]
    });
});
$(window).on('load', function() {
    var now_slide = $('.slick-slide.slick-current.slick-active.slick-center');
    now_slide.prev().addClass("extraClass");
    now_slide.prev().prev().addClass("extraprev");
    now_slide.next().addClass("extraClassnext");
    now_slide.next().next().addClass("extraClassnext-2");
});
// On before slide change event
$('.slider').on('init', function(event, slick) {
    $('.slider').on('afterChange', function() {
        $('.slick-slide').removeClass("extraClass extraprev extraClassnext extraClassnext-2");
        var now_slide = $('.slick-slide.slick-current.slick-active.slick-center');
        now_slide.prev().addClass("extraClass");
        now_slide.prev().prev().addClass("extraprev");
        now_slide.next().addClass("extraClassnext");
        now_slide.next().next().addClass("extraClassnext-2");
    })
});


$('.testing-carousel').owlCarousel({
            loop:true,
            margin:0,
            nav:true,
            dots:true,
            nav:false,
            responsive:{
                0:{
                    items:2
                },
                600:{
                    items:3
                },
                1000:{
                    items:4
                }
            }
        })